import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import styled from "styled-components"
import Img from "gatsby-image/withIEPolyfill"
import { mq, colors } from "../utils/presets"
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
// import { faFilePdf } from "@fortawesome/pro-light-svg-icons"

const Title = styled.h1`
  text-align: center;
  margin: 1rem auto 2rem;
  padding: 1rem 0;
  border-bottom: 3px solid ${colors.black};
  border-top: 3px solid ${colors.black};
  margin-right: 1rem;
  ${mq.xxl} {
    margin-right: 0 !important;
    padding-right: 0 !important;
  }
`

const Wrapper = styled.div`
  ${mq.tablet} {
    max-width: 1600px;
    margin: 0 auto;
    /* border-bottom: 3px solid ${colors.black}; */
    margin-bottom: 3rem;
  }
  .hr {
    margin-left: 1rem;
    border-bottom: 3px solid ${colors.black};
    margin-right: 1rem;
    
    ${mq.xxl} {
      margin-right: 0;
    }
  }
`

const Topimage = styled(Img)`
  ${mq.tablet} {
    margin-left: 2rem;
    float: right;
    width: 50%;
    height: 500px;
    margin-top: 3.8rem;
  }
`
const Textwrapper = styled.div`
  padding: 1rem;
  ${mq.tablet} {
    margin-right: 1rem;
    h1 {
      text-align: left;
      border-bottom: none;
      margin-bottom: 0;
      margin-right: 1rem;
    }
  }
  ${mq.tablet} {
    margin-right: 0;
    padding-right: 0;
  }
`

// const Button = styled.a`
//   /* This renders the buttons above... Edit me! */
//   display: inline-block;
//   padding: 0.5rem 1rem;
//   background: ${colors.black};
//   color: white;
//   font-family: "Open Sans";
//   font-weight: 400;
//   text-align: center;
//   font-size: 1rem;
//   text-decoration: none;
//   border-radius: 20px;
// `

const PageTemplate = ({ data }) => (
  <Layout>
    <SEO
      title={data.wordpressPage.title}
      description={data.wordpressPage.excerpt}
    />
    <Wrapper>
      <Topimage
        objectFit="cover"
        objectPosition="50% 50%"
        sizes={data.wordpressPage.acf.feat_img.localFile.childImageSharp.sizes}
        alt={data.wordpressPage.title}
      />
      <Textwrapper>
        <Title>{data.wordpressPage.title}</Title>
        <div dangerouslySetInnerHTML={{ __html: data.wordpressPage.content }} />
        {/* {data.wordpressPage.acf.download !== null && (
          <Button
            href={data.wordpressPage.acf.download.url}
            target={data.wordpressPage.acf.download.target}
          >
            <FontAwesomeIcon
              style={{ marginRight: "5" }}
              className="faicon"
              icon={faFilePdf}
            />{" "}
            {data.wordpressPage.acf.download.title}
          </Button>
        )} */}
      </Textwrapper>
      <hr className="hr" />
    </Wrapper>
  </Layout>
)
export default PageTemplate

export const query = graphql`
  query($id: Int!) {
    wordpressPage(wordpress_id: { eq: $id }) {
      title
      excerpt
      content
      acf {
        feat_img {
          localFile {
            childImageSharp {
              sizes(maxWidth: 1200) {
                ...GatsbyImageSharpSizes
              }
            }
          }
        }
        download {
          title
          url
          target
        }
      }
    }
  }
`
